<template>
  <div class="mission-page">
    <div class="mission-banner"></div>
    <div class="container">
      <div class="content-wrap">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/home">首页</a></li>
            <li class="breadcrumb-item active" aria-current="page">信息联动</li>
          </ol>
          <SearchBar @toSearch="toSearch"></SearchBar>
        </nav>
        <div>
          <ul class="nav nav-pills nav-green" id="myTab" role="tablist">
            <li
              class="nav-item"
              role="presentation"
              v-for="item in areaList"
              :key="item.area_id"
            >
              <button
                :class="[
                  'nav-link',
                  item.area_name == curArea.area_name ? 'active' : '',
                ]"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home-tab-pane"
                type="button"
                role="tab"
                aria-controls="home-tab-pane"
                aria-selected="true"
                @click="changeArea(item)"
              >
                {{ item.area_name }}
              </button>
            </li>
          </ul>
          <div class="mission-list">
            <Banner
              :width="1056"
              :height="550"
              :list="banners"
              v-if="banners.length > 0"
              @click="bannerClick"
            >
            </Banner>
            <div
              class="card"
              v-for="item in data.list"
              :key="item.news_id"
              @click="toDetail(item)"
            >
              <div class="card-img-top">
                <img :src="item.cover" />
              </div>
              <div class="card-body">
                <div class="card-title">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
          <Pagination
            @change="pageChanged"
            :current="1"
            :pageSize="24"
            :hideOnSinglePage="true"
            :total="parseInt(data.total)"
            :showQuickJumper="false"
            :showTotal="false"
            placement="center"
          />
          <!-- <PageNav @pageChanged="pageChanged"
                   :max="calcMaxPage(data.total)"
                   ref="page" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Banner from "@/components/Banner.vue";
import SearchBar from "@/components/SearchBar.vue";
import PageNav from "@/components/PageNav.vue";
import Pagination from "@/components/Pagination.vue";
import { missionBanners, missionList, areaList } from "@/network/API.js";
export default {
  components: {
    Banner,
    SearchBar,
    PageNav,
    Pagination,
  },
  data() {
    return {
      banners: [],
      data: {},
      areaList: [],
      curArea: {},
    };
  },
  created() {
    this.getAreaList();
  },
  methods: {
    toSearch(val) {
      this.searchKey = val;
      this.pageNumber = 1;
      this.getDataList();
    },
    changeArea(item) {
      this.curArea = item;
      this.getBanners();
    },
    bannerClick(item) {
      if (item.link_to) {
        this.$router.push({
          name: "MissionDetail",
          params: {
            id: item.link_to,
          },
        });
      }
      if (item.source_url) {
        window.open(item.source_url, "_blank");
      }
    },
    toDetail(item) {
      this.$router.push({
        name: "MissionDetail",
        params: {
          id: item.news_id,
        },
      });
    },
    pageChanged(val) {
      this.pageNumber = val.page;
      this.getDataList();
    },
    async getAreaList() {
      let data = await areaList({
        code: "130000",
      });
      this.areaList = [
        {
          area_id: "130000",
          area_name: "推荐",
        },
        ...data,
      ];
      this.curArea = this.areaList[0];
      this.getBanners();
    },
    async getBanners() {
      let data = await missionBanners(this.curArea.area_id);
      this.banners = data;
      this.getDataList();
    },
    async getDataList() {
      let data = await missionList({
        region: this.curArea.area_id == "130000" ? "" : this.curArea.area_id,
        page_size: 24,
        page: this.pageNumber,
        keyword: this.searchKey,
      });
      data.list.forEach((item) => {
        if (item.cover == "") {
          item.cover =
            "https://vote-image.hbzhengwu.com/uploads/mem/default.jpg";
        }
      });
      this.data = data;
    },
  },
};
</script>
<style>
.mission-banner {
  width: 100%;
  height: 300px;
  background: url(../../assets/safety/banner-mission.jpg) top center no-repeat;
  margin-bottom: -32px;
}

/* 选项卡 */

.nav-pills.nav-green .nav-link {
  margin: 0;
  margin-right: 20px;
  padding-left: 30px;
  padding-right: 30px;
  color: #333;
}

.nav-pills.nav-green .nav-link:hover {
  color: #1cc579;
}

.nav-pills.nav-green .nav-link.active {
  background: linear-gradient(107deg, #2fe091 0%, #1cc579 99%);
  color: #fff;
  border: none;
}

.mission-list {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -15px 20px -15px;
}
.mission-list .card {
  margin: 15px;
  width: calc((100% - 90px) / 3);
  border: none;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 10px;
  transition: all linear 0.2s;
}
.mission-list .card:hover {
  transform: translateY(-5px);
}
nav {
  position: relative;
}
.search-box {
  top: 10px;
}
.carousel-wrapper {
  width: 1056px !important;
  height: 550px !important;
  margin: 15px !important;
}
</style>
